/* eslint-disable */
import React from 'react';
export interface IQuestionContent {
  id: number;
  question: string;
  answer: string;
  img?: string;
}

export const questionContent: IQuestionContent[] = [
  {
    id: 1,
    question: 'How does 1001 Squares of NFT work?',
    answer:
      '1001 Squares of NFT is a platform designed to provide NFT sellers with maximum exposure. Our platform is divided into 1001+ squares, where users can add their NFTs for a chosen amount of time. Each NFT is ranked based on its ad price per day, which is calculated by the amount paid divided by the time listed. The higher your ad price per day, the higher your NFT ranks across galleries, giving you greater visibility.',
  },
  {
    id: 2,
    question: 'How are the 1001 squares divided?',
    answer:
      'The platform is divided into 12 blocks with varying square sizes, ranging from 1 to 400 squares per block, totaling 1001 squares. This allows NFTs of all types and prices to have a chance at high visibility. Squares 1 and 1001 are the same size, making them premium spots for visibility. NFTs that fall below the 1001st position are moved into the DeadZone, where they remain active but cannot be filtered and typically receive less visibility than those in the first 12 blocks.',
  },
  {
    id: 3,
    question: 'How do I add my NFT to 1001 Squares of NFT?',
    answer:
      'To add your NFT, click the "+ADD NFT" button, then paste your NFT marketplace link. Our system will pull the NFT\'s details and suggest galleries where it can be posted. Follow the steps to complete the process. If your NFT isn\'t recognized, you can manually add it by providing its details.',
  },
  {
    id: 4,
    question: 'What NFT marketplace links are supported?',
    answer:
      'We support direct links from OpenSea, LooksRare, and Magic Eden. For other marketplaces, non-Solana Magic Eden NFTs, or non-Ethereum OpenSea NFTs, you can manually add the NFT by providing the necessary information.',
  },
  {
    id: 5,
    question: 'Can I add my NFT for free?',
    answer:
      'Yes, you can add your NFT for free in all eligible galleries for up to 90 days.',
  },
  {
    id: 6,
    question: 'What happens if my NFT is pushed below 1001?',
    answer:
      'NFTs below position 1001 move to the DeadZone, where they cannot be filtered but remain active and can still be found normally, linking potential buyers to your NFT’s listing. DeadZone NFTs can move back up if NFTs above expire. You can edit and track your NFT’s position from your profile at any time.',
  },
  {
    id: 7,
    question: 'Can I edit or update my NFT’s details after adding it?',
    answer:
      'Yes, you can edit your NFT’s position, time, and value settings through your profile. Some changes may require transaction fees to confirm the update on the blockchain.',
  },
  {
    id: 8,
    question: 'Can I change the price of my NFT?',
    answer:
      "You can change the price or listing type of your NFT on the original marketplace, but changes won't automatically reflect on our platform. You'll need to re-add the NFT if you want to update the price. We will be rolling out a feature to allow price updates soon.",
  },
  {
    id: 9,
    question: 'How many times can I add or clone the same NFT?',
    answer:
      'You can add the same NFT multiple times to showcase it in different blocks. Use the Clone button in your profile to quickly duplicate an NFT’s details and add it again.',
  },
  {
    id: 10,
    question: 'What happens to my NFT when the time I specified is over?',
    answer:
      'When your NFT’s active time expires, it will be removed from galleries but remain visible on your profile as an unlisted NFT. You can re-add it anytime.',
  },
  {
    id: 11,
    question: 'Can I add my NFT to the DeadZone?',
    answer:
      'Yes, your NFT can be added to the DeadZone. These NFTs remain active but cannot be filtered, and they typically receive less visibility than those in the first 12 blocks. NFTs in the DeadZone can still move back into the top 1001 as other NFTs expire.',
  },
  {
    id: 12,
    question: 'How are NFTs ranked on 1001 Squares of NFT?',
    answer:
      'NFTs are ranked by ad price per day, which is calculated by dividing the amount paid by the duration of time listed. The higher the ad price per day, the higher the NFT ranks across galleries.',
  },
  {
    id: 13,
    question: 'Why does my NFT’s position keep changing?',
    answer:
      'NFT rankings fluctuate based on newly added NFTs and the expiration of others. If a new NFT has a higher ad price per day than yours, your position will drop. When an NFT above yours expires, your NFT moves up in rank.',
  },
  {
    id: 14,
    question: 'How are NFTs with the same ad price per day ranked?',
    answer:
      'NFTs with the same ad price per day are ranked in order of arrival. However, identical ad prices are rare due to small differences in cryptocurrency conversion rates, except when the ad price is $0.00.',
  },
  {
    id: 15,
    question: 'How much time should I list my NFT for?',
    answer:
      'The longer your NFT is listed, the more opportunities it has for exposure. Consider how long you want it to be visible and aim for a duration and overall position that maximizes your NFT’s chances to be seen.',
  },
  {
    id: 16,
    question: 'On the 12 blocks, why is square 1001 the same size as square 1?',
    answer:
      "Square 1 and square 1001 are the same size to provide any NFT an equal chance for visibility at the extremes. However, square 1001 is harder to hold since it's the last position before the DeadZone.",
  },
  {
    id: 17,
    question: 'What is the DeadZone?',
    answer:
      'The DeadZone holds all NFTs ranked after position 1001. While these NFTs are not filterable, they are still active and can move back into the top 1001 as other NFTs expire.',
  },
  {
    id: 18,
    question: 'How many NFTs fit in the DeadZone?',
    answer: 'The DeadZone can hold an unlimited number of NFTs.',
  },
  {
    id: 19,
    question: 'What payment methods are accepted on 1001 Squares of NFT?',
    answer: ` We accept the following cryptocurrencies: 
        - Done (DONE)   
        - Solana (SOL) 
        - Ethereum (ETH)   
        - Binance Smart Chain (BNB)   
        - Avalanche (AVAX)  
        - Polygon (MATIC)  
        - Fantom (FTM) 
    `,
  },
  {
    id: 20,
    question: 'What is $DONE?',
    answer:
      '$DONE is the native token of 1001 Squares of NFT. It is used for adding NFTs and earning rewards for doing so. In the first month after launch, you will receive 99% of your ad spend instantly back in $DONE, and later 25%, by adding NFTs using Solana or $DONE.',
  },
  {
    id: 21,
    question: 'Do I need to have my NFT in the same wallet I use to pay?',
    answer:
      'No, you can use one wallet for payment and hold your NFT in a different wallet.',
  },
  {
    id: 22,
    question: 'What currency should I pay with?',
    answer:
      'We recommend using $DONE, SOL, AVAX, MATIC, or FTM for faster transactions and lower fees. ETH and BNB are also accepted. Note that rewards are only supported for payments above $0 in SOL or $DONE.',
  },
  {
    id: 23,
    question: 'Is 1001 Squares of NFT safe?',
    answer:
      'Yes, 1001 Squares of NFT is secure. We only allow users to submit NFT links from trusted marketplaces. Our smart contract payment system has been audited by Hashlock, Omniscia, and SourceHat to ensure safety and transparency. The platform never executes sales or transfers of NFTs—payments are purely for posting and promoting NFT links.',
  },
  {
    id: 24,
    question: 'What happens if I disconnect my wallet?',
    answer:
      'Disconnecting your wallet logs you out of the platform. Any active NFTs remain unaffected, and you can reconnect your wallet anytime to manage your profile.',
  },
  {
    id: 25,
    question: 'Why can’t I connect my wallet?',
    answer:
      'If you’re having trouble connecting your wallet, try disconnecting 1001squaresofnft.com from your wallet’s Dapp connections and reconnect. If the issue persists, contact us on Discord.',
  },
  {
    id: 26,
    question: 'How do I disconnect my wallet?',
    answer:
      'To disconnect your wallet, go to your profile, click the settings icon, and press the exit button next to your wallet address. This logs you out of the platform.',
  },

  {
    id: 27,
    question: 'When I clicked CONFIRM PAYMENT my transaction failed. Why?',
    answer:
      'Ensure you have enough funds to cover both the payment and transaction fees. If the error persists, check your internet connection or contact our support team on Discord.',
  },
  {
    id: 28,
    question: 'Is the sale of my NFT guaranteed?',
    answer:
      'While we provide visibility for your NFT, we cannot guarantee its sale. 1001 Squares of NFT is designed to increase exposure, making it more likely that potential buyers will find your NFT.',
  },
  {
    id: 29,
    question:
      'What is First place, Last place, 24H volume, and All time volume?',
    answer:
      '- First place: The amount paid to hold the top-ranking spot for a full day.\n' +
      '- Last place: The amount paid to hold the 1001st position for a full day.\n' +
      '- 24H volume: The total amount paid by all users over the last 24 hours.\n' +
      '- All time volume: The total amount paid by all users since the platform’s launch.',
  },
  {
    id: 30,
    question: 'What are the supported file types for NFTs?',
    answer:
      'We support the following file types: GIF, PNG, JPEG, JPG, WebM, WebP, MP4, SVG.',
  },
  {
    id: 31,
    question: 'What happens if I delete my NFT?',
    answer:
      'Deleting an active NFT removes it from all galleries, and any remaining time and value are lost. This action is irreversible.',
  },
];
