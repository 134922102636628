/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useState, useMemo, useRef } from 'react';
import { Box, Button, Grid, Avatar } from '@material-ui/core';
import CloseIcon from 'icons/CloseIcon';
import Search from '../Search';
import { useStyle } from './styles';
import RocketIconMobile from 'icons/RocketIconMobile';
import DiscordIcon from 'icons/DiscordIcon';
import TwitterIcon from 'icons/TwitterIcon';
import InstagramIcon from 'icons/InstagramIcon';
import AccountBalanceWallet from 'icons/AccountBalanceWallet';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateDialogStateAction } from 'store/actions/dialogActions';
import ConnectWalletDialog from 'components/ConnectWallet';
import { getMarket, getUserState, modeTheme } from 'store/selectors';
import { renderShortAddress } from 'common/helper';
import LogoutIcon from 'icons/LogoutIcon';
import { logoutUserAction } from 'store/actions/userActions';
import { useWeb3React } from '@web3-react/core';
import NotificationsNone from 'icons/NotificationsNone';
import PrivacyTip from 'icons/PrivacyTip';
import HelpOutline from 'icons/HelpOutline';
import NotificationSetting from 'components/MenuUser/NotificationSetting';
import makeBlockie from 'ethereum-blockies-base64';
import { DISCORD_LINK } from 'common/constant';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletEnum } from 'enums/wallet';
import { updateMarketStateAction } from 'store/actions/marketAction';
import DarkModeIcon from 'icons/DarkModeIcon';
import LightModeIcon from 'icons/LightModeIcon';
import { changeModeTheme } from 'store/actions/modeAction';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

export interface SearchModalProps {
  onClose: () => void;
}

export default function SearchModal(props: SearchModalProps) {
  const { onClose } = props;
  const classes = useStyle();
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const [isOpenNotification, setOpenNotification] = useState<
    string | undefined
  >();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const { deactivate, active } = useWeb3React();
  const { connected, disconnect } = useWallet();
  const market = useSelector(getMarket);
  const mode = useSelector(modeTheme);
  const modalRef = useRef(null);

  const activeWallet = useMemo(() => {
    if (market === WalletEnum.PHANTOM || market === WalletEnum.TIPLINK) {
      return connected;
    }
    if (
      market === WalletEnum.META_MASK ||
      market === WalletEnum.COIN_BASE ||
      market === WalletEnum.WALLET_CONNECT
    ) {
      return active;
    }
    return false;
  }, [active, connected, market]);

  const deactivateWallet = useCallback(() => {
    if (market === WalletEnum.PHANTOM || market === WalletEnum.TIPLINK) {
      disconnect();
    }
    if (
      market === WalletEnum.META_MASK ||
      market === WalletEnum.COIN_BASE ||
      market === WalletEnum.WALLET_CONNECT
    ) {
      deactivate();
    }
  }, [deactivate, disconnect, market]);

  const toggleActive = useCallback(() => {
    setActiveSearch(true);
  }, []);

  const offActive = useCallback(() => {
    setActiveSearch(false);
  }, []);

  const onDirectToStaticPage = useCallback(
    (path: string) => {
      if (location.pathname === path) return;
      history.push(path);
      onClose();
    },
    [location, history, onClose],
  );

  const onDirectToHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const onLogin = useCallback(() => {
    dispatch(
      updateDialogStateAction({
        open: true,
        component: ConnectWalletDialog,
        props: undefined,
      }),
    );
  }, [dispatch]);

  const onLogout = useCallback(() => {
    deactivateWallet();
    dispatch(updateMarketStateAction(null));
    dispatch(logoutUserAction());
    onDirectToHome();
  }, [deactivateWallet, dispatch, onDirectToHome]);

  const handleClickRocketIcon = useCallback(() => {
    onClose();
    onDirectToStaticPage('/nft');
  }, [onClose, onDirectToStaticPage]);

  const renderButton = useMemo(() => {
    if (user.user && activeWallet) {
      return (
        <Grid className={classes.wrapDisConnect} onClick={onLogout}>
          <Grid className={classes.textDisConnect}>DISCONNECT WALLET</Grid>
          <LogoutIcon color="#4fffa6" width={24} height={22} />
        </Grid>
      );
    } else {
      return (
        <Grid className={classes.wrapButtonConnect} onClick={onLogin}>
          <Grid className={classes.textButton}>CONNECT WALLET</Grid>
          <AccountBalanceWallet />
        </Grid>
      );
    }
  }, [user, activeWallet, classes, onLogin, onLogout]);

  useOnClickOutside(modalRef, onClose);

  return (
    <>
      {isOpenNotification !== 'notification' ? (
        <Grid className={classes.wrapModal} ref={modalRef}>
          <Grid className={classes.wrapIconClose}>
            <Button className={classes.closeBtn} onClick={onClose}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid className={classes.wrapMenuSearch}>
            <Grid
              onClick={toggleActive}
              className={clsx(classes.wrapInputSearch, {
                [classes.wrapInputSearchActive]:
                  activeSearch || location.pathname !== '/',
              })}
            >
              <Search offActive={offActive} offSearchModal={onClose} />
            </Grid>
            {!activeSearch && location.pathname === '/' && (
              <>
                <div className={classes.borderSearch}></div>
                <RocketIconMobile onClick={handleClickRocketIcon} />
              </>
            )}
          </Grid>
          <Grid className={classes.wrapMenu}>
            <Grid
              className={clsx(classes.itemMenu, {
                [classes.itemMenuActive]: location.pathname === '/litepaper',
              })}
              onClick={() => onDirectToStaticPage('/litepaper')}
            >
              LITEPAPER
            </Grid>
            <Grid
              className={clsx(classes.itemMenu, {
                [classes.itemMenuActive]: location.pathname === '/faq/all',
              })}
              onClick={() => onDirectToStaticPage('/faq/all')}
            >
              FAQ
            </Grid>
            <Grid
              className={clsx(classes.itemMenu, {
                [classes.itemMenuActive]: location.pathname === '/contact',
              })}
              onClick={() => onDirectToStaticPage('/contact')}
            >
              CONTACT
            </Grid>
          </Grid>
          <div className={classes.borderTopIcon}></div>
          <Box className={classes.icon}>
            <a className={classes.iconLink} href={DISCORD_LINK} target="_blank">
              <DiscordIcon width={24} height={24} />
            </a>
            <a
              className={classes.iconLink}
              href="https://twitter.com/mintedgem"
              target="_blank"
            >
              <TwitterIcon width={24} height={24} />
            </a>
            <a
              className={classes.iconLink}
              href="https://www.instagram.com/mintedgem/"
              target="_blank"
            >
              <InstagramIcon width={24} height={24} />
            </a>
          </Box>
          <div className={classes.borderBottomIcon}></div>
          {user.user && activeWallet && (
            <Grid
              className={classes.wrapInforUser}
              onClick={() => onDirectToStaticPage('/user')}
            >
              <Box className={classes.avatar}>
                <Avatar
                  src={makeBlockie(user.user?.address || '')}
                  className={classes.avatarIcon}
                />
              </Box>
              <Grid>
                <Grid className={classes.addressWallet}>
                  {renderShortAddress(user.user.address, 6, 4)}
                </Grid>
                <Grid className={classes.wallet}>Wallet</Grid>
              </Grid>
            </Grid>
          )}
          {renderButton}
          <Grid>
            <button
              className={classes.itemNotication}
              onClick={() => {
                setOpenNotification('notification');
              }}
              disabled={!user.user}
            >
              <NotificationsNone />
              <Grid className={classes.textNotication}>Notifications</Grid>
            </button>
            <button
              className={classes.itemNotication}
              onClick={() => {
                history.push('/terms-of-service', {
                  signal: 'terms-of-service',
                });
                onClose();
              }}
            >
              <PrivacyTip />
              <Grid className={classes.textNotication}>Privacy</Grid>
            </button>
            <button
              className={classes.itemNotication}
              onClick={() => {
                history.push('/faq/all');
                onClose();
              }}
            >
              <HelpOutline />
              <Grid className={classes.textNotication}>Help</Grid>
            </button>
          </Grid>
          {mode === 'light' ? (
            <button
              className={classes.btnMode}
              onClick={() => dispatch(changeModeTheme('dark'))}
            >
              <DarkModeIcon width={20} height={20} />
              <span>Dark mode</span>
            </button>
          ) : (
            <button
              className={classes.btnMode}
              onClick={() => dispatch(changeModeTheme('light'))}
            >
              <LightModeIcon width={20} height={20} />
              <span>Light mode</span>
            </button>
          )}
        </Grid>
      ) : (
        <Grid className={classes.wrapModal}>
          <Grid className={classes.wrapIconClose}>
            <Button className={classes.closeBtn} onClick={onClose}>
              <CloseIcon />
            </Button>
          </Grid>
          {user.user && activeWallet && (
            <Grid
              className={classes.wrapInforUser}
              onClick={() => onDirectToStaticPage('/user')}
            >
              <Box className={classes.avatar}>
                <Avatar
                  src={makeBlockie(user.user?.address || '')}
                  className={classes.avatarIcon}
                />
              </Box>
              <Grid>
                <Grid className={classes.addressWallet}>
                  {renderShortAddress(user.user.address, 6, 4)}
                </Grid>
                <Grid className={classes.wallet}>Wallet</Grid>
              </Grid>
            </Grid>
          )}
          <NotificationSetting setStateSetting={setOpenNotification} />
          {mode === 'light' ? (
            <button
              className={classes.btnMode}
              onClick={() => dispatch(changeModeTheme('dark'))}
            >
              <DarkModeIcon width={20} height={20} />
              <span>Dark mode</span>
            </button>
          ) : (
            <button
              className={classes.btnMode}
              onClick={() => dispatch(changeModeTheme('light'))}
            >
              <LightModeIcon width={20} height={20} />
              <span>Light mode</span>
            </button>
          )}
        </Grid>
      )}
    </>
  );
}
