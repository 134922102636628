/* eslint-disable react/jsx-no-target-blank */
import { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import AddForMenuIcon from 'icons/AddForMenuIcon';
import DiscordIcon from 'icons/DiscordIcon';
import InstagramIcon from 'icons/InstagramIcon';
import Img1001_Logo from 'assets/1001_Logo.svg';
import MenuIcon from 'icons/MenuIcon';
import TwitterIcon from 'icons/TwitterIcon';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getPriceNFTBaseOnDollarAction } from 'store/actions/nftActions';
import { updateAppStateAction } from 'store/actions/appActions';
import { getBlocksCategories, scrollState } from 'store/selectors';
import secureStorageUtils from 'utils/secureStorage';
import { SecureStorageEnum } from 'enums/auth';
import Search from './Search';
import SearchIcon from 'icons/SearchIcon';
import { DISCORD_LINK } from 'common/constant';
import { getAllSearchCategoriesAction } from 'store/actions/categoriesActions';
import { SortEnum } from 'enums/sortEnum';
import { CategorySortOptions } from 'enums/categories';

declare global {
  interface Window {
    isScroll: boolean;
  }
}

interface IMenu {
  displayModal: () => void;
  isDeadzone: boolean;
  onSearchModal?: () => void;
  offSearchModal?: () => void;
}

interface Istyle {
  isScrolling: boolean;
  isDeadzone: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  filterCategory: boolean;
  isCategoryPage: boolean;
}

const Menu = (props: IMenu) => {
  const { isDeadzone, onSearchModal, offSearchModal } = props;
  const isScrolling = useSelector(scrollState);
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md')) && !isDesktop;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const blockCategories = useSelector(getBlocksCategories);

  const filterCategory = useMemo(() => {
    if (blockCategories.blockCategories.category) {
      return true;
    } else {
      return false;
    }
  }, [blockCategories]);

  const isCategoryPage = useMemo(
    () => location.pathname === '/categories',
    [location.pathname],
  );

  const classes = useStyles({
    isScrolling,
    isDeadzone,
    isDesktop,
    isTablet,
    isMobile,
    filterCategory,
    isCategoryPage,
  });

  const blank = (url: string) => {
    window.open(url, '_blank');
  };

  const onRedirectToAddNFT = () => {
    if (history.location.pathname === '/add-nft') {
      const nftToEstimate = JSON.parse(
        secureStorageUtils.getItemSS(SecureStorageEnum.NFT_TO_ESTIMATE) || '',
      );

      if (nftToEstimate) {
        secureStorageUtils.removeItemSS(SecureStorageEnum.ADD_NFT_STATUS);
        secureStorageUtils.removeItemSS(SecureStorageEnum.NFT_TO_ESTIMATE);
        window.location.reload();
        window.scrollTo(0, 0);
      }
      return;
    }
    history.push('/add-nft');
  };

  // const onDirectToStaticPage = (path: string) => {
  //   if (location.pathname === path) return;
  //   history.push(path);
  // };

  const onScroll = useCallback(
    (e: any) => {
      if (window.scrollY && !window.isScroll) {
        window.isScroll = true;
        dispatch(
          updateAppStateAction({
            isScrolling: true,
          }),
        );
      }
      if (!window.scrollY && window.isScroll) {
        window.isScroll = false;
        dispatch(
          updateAppStateAction({
            isScrolling: false,
          }),
        );
      }
    },
    [dispatch],
  );

  const onRedirectToHome = useCallback(() => {
    if (history.location.pathname !== '/') {
      history.push('/');
    } else {
      window.location.reload();
      window.scroll({ top: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, dispatch]);

  useEffect(() => {
    if (!isDesktop) return;
    window.addEventListener('scroll', onScroll);
    return () => {
      window?.removeEventListener('scroll', onScroll);
      window.isScroll = false;
      dispatch(
        updateAppStateAction({
          isScrolling: false,
        }),
      );
    };
  }, [isDesktop, onScroll, dispatch, location.pathname]);

  useEffect(() => {
    dispatch(getPriceNFTBaseOnDollarAction());
  }, [dispatch]);

  // call api first before click side navigation
  useEffect(() => {
    dispatch(
      getAllSearchCategoriesAction({
        includeIcon: true,
        sortType: SortEnum.Desc,
        sortBy: CategorySortOptions.TOTAL_ITEMS,
      }),
    );
  }, [dispatch]);

  const logo = useMemo(() => {
    return isDesktop && isScrolling ? (
      <Box className={classes.logo} onClick={onRedirectToHome}>
        {/* <ScrollingLogoIcon width={216} height={60} /> */}
        <img className={classes.logoAppMobile} src={Img1001_Logo} alt="" />
      </Box>
    ) : isMobile ? (
      <Box className={clsx(classes.logo, classes.logoMobile)}>
        <Button disableTouchRipple onClick={onRedirectToHome}>
          <img className={classes.logoAppMobile} src={Img1001_Logo} alt="" />
          {/* <IconLogoSmall height={48} width={37} /> */}
        </Button>
      </Box>
    ) : (
      <Box className={classes.logo} onClick={onRedirectToHome}>
        <img className={classes.logoApp} src={Img1001_Logo} alt="" />
        {/* <LogoIcon width={216} height={60} /> */}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isScrolling, isMobile, classes]);

  return (
    <Box
      className={clsx(classes.main, {
        [classes.deadzone]:
          isDeadzone && isDesktop && location.pathname === '/nft',
        [classes.deadzoneBackground]:
          isDeadzone && !isDesktop && location.pathname === '/nft',
        [classes.scrolling]: isDesktop && isScrolling && !isDeadzone,
        [classes.unscrollingInDZ]: !isScrolling && isDeadzone,
      })}
      id={TOP_MENU_ID}
    >
      {logo}
      {isDesktop && (
        <Box className={classes.menu_desktop}>
          <Box className={classes.menuSearch_desktop}>
            <Search offSearchModal={offSearchModal} />
          </Box>
          <Box className={classes.menuWrap}>
            <div className={classes.menuLeft_desktop}>
              <Link
                to="/litepaper"
                // dont remove this code, it's for bold text when active
                style={{ width: '80px' }}
                className={clsx(classes.menuLeftRoute, {
                  [classes.menuLeftRouteActive]:
                    location.pathname === '/litepaper',
                })}
              >
                LITEPAPER
              </Link>
              <Link
                to="/faq/all"
                // dont remove this code, it's for bold text when active
                style={{ width: '33px' }}
                className={clsx(classes.menuLeftRoute, {
                  [classes.menuLeftRouteActive]:
                    location.pathname === '/faq/all',
                })}
              >
                FAQ
              </Link>
              <Link
                to="/contact"
                // dont remove this code, it's for bold text when active
                style={{ width: '56px' }}
                className={clsx(classes.menuLeftRoute, {
                  [classes.menuLeftRouteActive]:
                    location.pathname === '/contact',
                })}
              >
                $DONE
              </Link>
            </div>
            <Box className={classes.menuMid_desktop}></Box>
            <Box className={classes.menuRight_desktop}>
              <div
                className={classes.icon_desktop}
                onClick={() => blank(DISCORD_LINK)}
              >
                <a
                  href={DISCORD_LINK}
                  target="_blank"
                  className={classes.iconLink}
                >
                  <DiscordIcon width={24} height={24} />
                </a>
              </div>
              <div
                className={classes.icon_desktop}
                onClick={() => blank('https://x.com/1001NFTs')}
              >
                <a
                  href="https://x.com/1001NFTs"
                  target="_blank"
                  className={classes.iconLink}
                >
                  <TwitterIcon width={24} height={24} />
                </a>
              </div>
              <div
                className={classes.icon_desktop}
                onClick={() =>
                  blank('https://www.instagram.com/1001squaresofnft')
                }
              >
                <a
                  href="https://www.instagram.com/1001squaresofnft"
                  target="_blank"
                  className={classes.iconLink}
                >
                  <InstagramIcon width={24} height={24} />
                </a>
              </div>
            </Box>
          </Box>
        </Box>
      )}
      {!isDesktop && (isTablet || isMobile) && (
        <Box className={classes.menu_tablet}>
          <Box
            className={clsx(classes.search_tablet, 'center-root')}
            onClick={onSearchModal}
          >
            <SearchIcon
              width={isMobile ? 24 : 21.6}
              height={isMobile ? 24 : 21.6}
            />
          </Box>
          <Box
            className={clsx(classes.addNft_tablet, 'center-root')}
            onClick={onRedirectToAddNFT}
          >
            <AddForMenuIcon
              width={isMobile ? 24 : 21.6}
              height={isMobile ? 24 : 21.6}
            />
          </Box>
          <Box
            className={clsx(classes.openMenu_tablet, 'center-root', {
              [classes.deadzoneBackground]: isDeadzone,
            })}
            onClick={onSearchModal}
          >
            <MenuIcon
              width={isMobile ? 24 : 28.8}
              height={isMobile ? 24 : 28.8}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Menu;

export const TOP_MENU_ID = 'TOP_MENU_ID';

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    logoApp: {
      height: 60,
      paddingLeft: 0,
    },
    logoAppMobile: {
      height: 48,
    },
    deadzone: {
      backgroundColor: '#B7B71D !important',
      height: 40,
    },
    deadzoneBackground: {
      backgroundColor: ({ filterCategory }: Istyle) => {
        if (filterCategory) {
          return ' #8d89ce !important';
        }
        return ' #DDE542 !important';
      },
    },
    main: {
      // transition: '0.1s',
      position: 'fixed',
      zIndex: 3,
      marginTop: 60,
      paddingInline: ({ isCategoryPage }: { isCategoryPage: boolean }) =>
        isCategoryPage ? 24 : 32,
      width: '100%',
      display: 'flex',
      backgroundColor: ({ isDeadzone }: { isDeadzone: boolean }) => {
        if (isDeadzone) return theme.colors.deadzone;
        if (!isDarkMode) return theme.colors.backgroundLight;
        if (isDarkMode) return theme.colors.backgroundDark;
      },
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: 36,
      },
      [theme.breakpoints.down('sm')]: {
        paddingInline: '16px !important',
      },
      height: ({ isScrolling, isDesktop }: Istyle) => {
        if (isDesktop && isScrolling) {
          return 76;
        }
        if (!isDesktop) {
          return 100;
        }
        return 108;
      },
    },
    unscrollingInDZ: {
      backgroundColor: ({ filterCategory }: Istyle) => {
        if (filterCategory) {
          return ' #8d89ce !important';
        }
        return ' #DDE542 !important';
      },
    },
    scrolling: {
      backgroundColor: isDarkMode
        ? theme.colors.blackOlive + ' !important'
        : theme.colors.cultured + ' !important',
    },
    logo: {
      maxHeight: 60,
      marginRight: 20,
      transition: '0.2s',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        marginRight: 32,
      },
      [theme.breakpoints.down('md')]: {
        flex: 2,
      },
      [theme.breakpoints.down('sm')]: {
        flex: 4,
        marginRight: 0,
      },
    },
    menu_desktop: {
      height: 'inherit',
      flex: 4,
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    menuSearch_desktop: {
      display: 'flex',
      flex: 7,
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginRight: 24,
    },
    menuWrap: {
      display: 'flex',
      flex: 2,
      alignItems: 'center',
    },
    menuLeft_desktop: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      gap: 16,
      marginRight: 32,
    },
    menuLeftRoute: {
      textDecoration: 'none',
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'center',
      color: ({ isScrolling }: Istyle) => {
        return isDarkMode ? theme.colors.white : theme.colors.black;
      },

      '&:hover': {
        color: ({ isScrolling }: Istyle) => {
          if (isScrolling) return theme.colors.primaryLight;
          return isDarkMode
            ? theme.colors.secondaryDark
            : theme.colors.primaryLight;
        },
        // hover text but dont resize text (font weight alternative)
        // textShadow: ({ isScrolling }: Istyle) => {
        //   if (isScrolling) return `1px 0 0 ${theme.colors.primaryLight}`;
        //   return isDarkMode
        //     ? `1px 0 0 ${theme.colors.secondaryDark}`
        //     : `1px 0 0 ${theme.colors.primaryLight}`;
        // },
        fontWeight: 800,
      },
    },
    menuLeftRouteActive: {
      color: ({ isScrolling }: Istyle) => {
        if (isScrolling) return theme.colors.primaryLight;
        return isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight;
      },
      fontWeight: 800,
    },
    menuMid_desktop: {
      height: '24px',
      width: '1.5px',
      backgroundColor: isDarkMode ? theme.colors.white : theme.colors.black,
      opacity: 0.2,
    },
    menuRight_desktop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginLeft: 22,
    },
    item_desktop: {
      margin: '0px 8px',
      height: '60px',
      padding: '18px 8px',
      fontSize: 16,
      textAlign: 'center',
      cursor: 'pointer',
      '&:hover': {
        color: ({
          isScrolling,
          isDeadzone,
        }: {
          isScrolling: boolean;
          isDeadzone: boolean;
        }) => (isDeadzone || isScrolling ? '#1b1464' : '#4fffa6'),
        fontWeight: 700,
        borderBottom: '1px solid #4fffa6',
      },
    },
    follow: {
      margin: 18,
      fontSize: 12,
      width: 60,
    },
    icon_desktop: {
      width: 36,
      height: 36,
      margin: 4,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '& a': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:hover': {
        backgroundColor: ({
          isScrolling,
        }: {
          isScrolling: boolean;
          isDeadzone: boolean;
        }) => (isDarkMode ? '#242424' : 'rgba(0, 0, 0, 0.08)'),
      },
    },
    menu_tablet: {
      flex: 4,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
      },
      [theme.breakpoints.down('sm')]: {},
    },

    addNft_tablet: {
      borderWidth: 1,
      height: 60,
      width: 60,
      borderColor: '#1b1464',
      backgroundColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      color: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,

      [theme.breakpoints.down('md')]: {
        marginRight: 14,
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 12,
        height: 48,
        width: 48,
      },
    },
    openMenu_tablet: {
      height: 60,
      width: 60,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      backgroundColor: isDarkMode
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight,
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      [theme.breakpoints.down('sm')]: {
        height: 48,
        width: 48,
      },
    },
    highLightText: {
      color: ({ isScrolling }: Istyle) => (isScrolling ? '#FFFFFF' : '#4fffa6'),
      fontWeight: 700,
    },
    logoMobile: {
      width: '50%',
      '& button': {
        paddingRight: 24,
        paddingLeft: 0,
      },
      '& svg': {
        maxWidth: 180,
        width: '100%',
      },
    },
    search_tablet: {
      borderWidth: 1,
      height: 60,
      width: 60,
      color: isDarkMode ? theme.colors.primaryDark : theme.colors.white,
      borderColor: isDarkMode ? theme.colors.primaryDark : theme.colors.white,
      backgroundColor: isDarkMode
        ? theme.colors.white
        : theme.colors.secondaryLight,

      [theme.breakpoints.down('md')]: {
        marginRight: 14,
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 12,
        height: 48,
        width: 48,
      },
    },
    iconLink: {
      color: ({ isScrolling }: { isScrolling?: boolean }) => {
        return isDarkMode ? theme.colors.white : theme.colors.black;
      },
    },
  };
});
