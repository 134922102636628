import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { modeTheme } from 'store/selectors';

interface IRocketIconMobile {
  onClick: () => void;
}

function RocketIconMobile({ onClick }: IRocketIconMobile) {
  const mode = useSelector(modeTheme);
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect x="5" y="5" width="36" height="36" rx="18" fill="#1b1464" />
      <g clipPath="url(#clip0_169_25105)">
        <path
          d="M19.8938 27.0283L18.9282 26.0627C18.6625 25.7971 18.5688 25.4127 18.6875 25.0565C18.7813 24.7783 18.9063 24.4158 19.0563 24.0002H15.75C15.4813 24.0002 15.2313 23.8565 15.0969 23.6221C14.9625 23.3877 14.9657 23.1002 15.1032 22.869L16.7438 20.1033C17.15 19.419 17.8844 19.0002 18.6782 19.0002H21.25C21.325 18.8752 21.4 18.7596 21.475 18.6471C24.0344 14.8721 27.8469 14.7471 30.1219 15.1658C30.4844 15.2315 30.7657 15.5158 30.8344 15.8783C31.2532 18.1565 31.125 21.9658 27.3532 24.5252C27.2438 24.6002 27.125 24.6752 27 24.7502V27.3221C27 28.1158 26.5813 28.8533 25.8969 29.2565L23.1313 30.8971C22.9 31.0346 22.6125 31.0377 22.3782 30.9033C22.1438 30.769 22 30.5221 22 30.2502V26.9002C21.5594 27.0533 21.175 27.1783 20.8844 27.2721C20.5344 27.3846 20.1532 27.2877 19.8907 27.0283H19.8938ZM27 20.2502C27.6907 20.2502 28.25 19.6908 28.25 19.0002C28.25 18.3096 27.6907 17.7502 27 17.7502C26.3094 17.7502 25.75 18.3096 25.75 19.0002C25.75 19.6908 26.3094 20.2502 27 20.2502Z"
          fill="white"
        />
      </g>
      <rect
        x="5"
        y="5"
        width="36"
        height="36"
        rx="18"
        stroke="white"
        strokeWidth="4"
      />
      <rect
        x="1.5"
        y="1.5"
        width="43"
        height="43"
        rx="21.5"
        stroke={mode === 'dark' ? '#302F45' : '#4fffa6'}
        strokeOpacity="0.24"
        strokeWidth="3"
      />
      <defs>
        <clipPath id="clip0_169_25105">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(15 15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(RocketIconMobile);
