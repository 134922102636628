import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    sortOption: {
      width: 161,
    },
    sortOptionContainer: {
      alignItems: 'center',
      height: 60,
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '66px',
        width: '150px',
      },
    },
    sortLabelContainer: {
      paddingRight: 8,
    },
    sortLabel: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '12px',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      width: '57px',
    },
    tableTitle: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      fontSize: 12,
      textAlign: 'center',
    },

    avatarSection: {
      width: '8%',
      [theme.breakpoints.down('sm')]: {
        width: '8%',
      },
    },
    nameSection: {
      width: '19%',
      [theme.breakpoints.down('sm')]: {
        width: '25%',
      },
    },
    ownersSection: {
      width: '16%',
    },
    firstPlaceSection: {
      width: '16%',
      [theme.breakpoints.down('sm')]: {
        width: '22%',
        '& .MuiButtonBase-root div': {
          marginLeft: '0px',
        },
      },
    },
    lastPlaceSection: {
      width: '16%',
      [theme.breakpoints.down('sm')]: {
        width: '22%',
        '& .MuiButtonBase-root div': {
          marginLeft: '0px',
        },
      },
    },
    volumeSection: {
      width: '23%',
      [theme.breakpoints.down('sm')]: {
        width: '27%',
        '& .MuiButtonBase-root div': {
          marginLeft: '0px',
        },
      },
    },

    tableTitleExpandRow: {
      width: '100%',
    },
    table: {
      '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
        padding: 0,
        height: '32px',
        lineHeight: '32px',
        [theme.breakpoints.down('sm')]: {
          height: '42px',
          lineHeight: '42px',
        },
      },
      '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(1)':
        {
          textAlign: 'left',
        },
      '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(3)':
        {
          [theme.breakpoints.down('sm')]: {},
        },
      '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(4)':
        {
          [theme.breakpoints.down('sm')]: {},
        },
      '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(5)':
        {
          [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
          },
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
          },
        },
      '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(6)':
        {
          [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
          },
          [theme.breakpoints.down('sm')]: {},
        },
      '& .MuiTableBody-root .MuiTableRow-root': {
        height: '64px',
        width: '100%',
        padding: '6px 8px',
        [theme.breakpoints.down('sm')]: {
          height: '50px',
        },
      },
      '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root': {
        padding: 0,
        borderBottom: `1px solid ${
          isDarkMode ? theme.colors.blackOlive : theme.colors.cultured
        }`,
        paddingTop: '8px',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '14px',
        },
      },
      '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(2)':
        {
          padding: 0,
          borderBottom: `1px solid ${
            isDarkMode ? theme.colors.blackOlive : theme.colors.cultured
          }`,
          paddingTop: '14px',
          [theme.breakpoints.down('sm')]: {
            paddingTop: '8px',
            paddingLeft: '8px',
          },
        },
      '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(3)':
        {
          padding: 0,
          borderBottom: `1px solid ${
            isDarkMode ? theme.colors.blackOlive : theme.colors.cultured
          }`,
          paddingTop: '20px',
          paddingLeft: '16px',
          [theme.breakpoints.down('md')]: {
            paddingTop: '16px',
            paddingLeft: '16px',
          },
          [theme.breakpoints.down('sm')]: {
            paddingTop: '16px',
            paddingLeft: '0px',
          },
        },
      '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(4)':
        {
          padding: 0,
          borderBottom: `1px solid ${
            isDarkMode ? theme.colors.blackOlive : theme.colors.cultured
          }`,
          paddingTop: '20px',
          paddingLeft: '10px',
          [theme.breakpoints.down('md')]: {
            paddingTop: '16px',
            paddingLeft: '10px',
          },
          [theme.breakpoints.down('sm')]: {
            paddingTop: '16px',
            paddingLeft: '0px',
          },
        },
      '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(5)':
        {
          padding: 0,
          borderBottom: `1px solid ${
            isDarkMode ? theme.colors.blackOlive : theme.colors.cultured
          }`,
          paddingTop: '20px',
          paddingLeft: '40px',
          [theme.breakpoints.down('md')]: {
            paddingLeft: '10px',
          },
          [theme.breakpoints.down('sm')]: {
            paddingTop: '16px',
            paddingLeft: '0px',
          },
        },
      '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(6)':
        {
          padding: 0,
          borderBottom: `1px solid ${
            isDarkMode ? theme.colors.blackOlive : theme.colors.cultured
          }`,
          paddingTop: '20px',
          paddingLeft: '45px',
          [theme.breakpoints.down('md')]: {
            paddingLeft: '10px',
          },
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingTop: '16px',
          },
        },
      '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root .MuiTypography-root':
        {
          fontSize: '14px',
          fontWeight: 400,
          color: isDarkMode ? theme.colors.white : theme.colors.secondaryLight,
        },
      '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(2) .MuiTypography-root':
        {
          fontSize: '14px',
          fontWeight: 700,
          width: '150px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            fontWeight: 600,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '66px',
          },
        },
    },
    wrapItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    iconUpDown: {
      paddingBottom: '2px',
      marginLeft: '2px',
    },
    titleImg: {
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      marginLeft: '8px',
    },
    imgCategories: {
      cursor: 'pointer',
      '& .MuiAvatar-root': {
        height: '48px',
        width: '48px',
        [theme.breakpoints.down('sm')]: {
          height: '24px',
          width: '24px',
        },
      },
    },
    wrapNameCategories: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    countCategories: {
      color: '#4fffa6',
      fontSize: '14px',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    root: {
      maxWidth: '913px',
      margin: '0 auto',
      marginBottom: '20px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: 800,
        '& .MuiTableContainer-root': {
          overflowX: 'hidden',
        },
        marginTop: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 600,
        '& .MuiTableContainer-root': {
          overflowX: 'hidden',
        },
      },
    },
    wrapTitleTop: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '16px',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    textTopCategories: {
      fontSize: '20px',
      fontWeight: 700,
      color: isDarkMode ? theme.colors.white : theme.colors.black,
    },
    wrapTableCategories: {
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
      '& table tbody tr > th': {
        height: '64px !important',
        [theme.breakpoints.down('sm')]: {
          height: '50px !important',
        },
      },
      '& table tbody tr > th:nth-child(n + 3)': {
        padding: '0 !important',
      },
    },
    wrapButtonLoad: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '64px',
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '48px',
      },
    },
    button: {
      width: '143px',
      height: '60px',
      padding: '18px 24px 18px 24px',
      color: '#8C89D1',
      border: '1px solid #8C89D1',
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    textTitle24Volumn: {
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        whiteSpace: 'break-spaces',
        height: '34px',
        lineHeight: '17px',
        textAlign: 'left',
        paddingLeft: '14px',
      },
    },
    textItemData: {
      position: 'relative',
      color: 'red',
    },
    wrapLoading: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '10px',
    },
    status: {
      position: 'absolute',
      right: -15,
      height: 21,
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      placeItems: 'center',
    },
  };
});
