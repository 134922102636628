/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyle } from './style';
// import LogoWhiteIcon from 'icons/LogoWhiteIcon';
import Img1001_Logo from 'assets/1001_Logo.svg';
import DiscordIcon from 'icons/DiscordIcon';
import TwitterIcon from 'icons/TwitterIcon';
import InstagramIcon from 'icons/InstagramIcon';
import clsx from 'clsx';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { clientRoutesEnum } from 'enums/routes';
import { DISCORD_LINK } from 'common/constant';
import { clientRoutesEnum } from 'enums/routes';

interface IProps {
  className?: string;
}

function AddNFTFooter(props: IProps) {
  const classes = useStyle();
  const location = useLocation();
  const history = useHistory();

  const blank = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className={clsx(props.className, classes.container)}>
      <Box display={'flex'} className={classes.topContainer}>
        <Box className={classes.leftItem}>
          <img className={classes.logoApp} src={Img1001_Logo} alt="" />
          {/* <LogoWhiteIcon width={174} height={32} /> */}
          <Typography className={classes.textFooter}>
            The #1 NFT space.
          </Typography>
        </Box>
        <Box className={classes.midleItem}>
          <Link
            to="/litepaper"
            className={clsx({
              [classes.linkActive]: location.pathname === '/litepaper',
            })}
          >
            <Typography>LITEPAPER</Typography>
          </Link>
          <Link
            to="/faq/all"
            className={clsx({
              [classes.linkActive]: location.pathname === '/faq/all',
            })}
          >
            <Typography>FAQ</Typography>
          </Link>
          <Link to={clientRoutesEnum.CONTACT}>
            <Typography>$DONE</Typography>
          </Link>
        </Box>
        <Box color={'white'} className={classes.rightItem}>
          {/* <Typography>Follow us</Typography> */}
          <div className={classes.socialIcon}>
            <div
              className={clsx(classes.iconLinkWrapper)}
              onClick={() => blank(DISCORD_LINK)}
            >
              <a
                href={DISCORD_LINK}
                target="_blank"
                className={classes.iconLink}
              >
                <DiscordIcon width={16} height={16} />
              </a>
            </div>

            <div
              className={clsx(classes.iconLinkWrapper, classes.iconWrapSecond)}
              onClick={() => blank('https://x.com/1001NFTs')}
            >
              <a
                href="https://x.com/1001NFTs"
                target="_blank"
                className={classes.iconLink}
              >
                <TwitterIcon fontSize={16} />
              </a>
            </div>
            <div
              className={clsx(classes.iconLinkWrapper)}
              onClick={() =>
                blank('https://www.instagram.com/1001squaresofnft')
              }
            >
              <a
                href="https://www.instagram.com/1001squaresofnft"
                target="_blank"
                className={classes.iconLink}
              >
                <InstagramIcon width={16} height={16} />
              </a>
            </div>
          </div>
        </Box>
      </Box>
      <Box className={classes.copyRight}>
        <Typography>
          Copyright 2024 1001 Squares of NFT
          <span className={classes.dot}>&#183;</span>
          <Link to={'/terms-of-service'} className={classes.linkRouter}>
            <span className={classes.link}>Terms</span>
          </Link>
          <span className={classes.dot}>&#183;</span>
          <span
            className={classes.linkRouter}
            onClick={() =>
              history.push('/terms-of-service', { signal: 'terms-of-service' })
            }
          >
            <span className={classes.link}>Privacy</span>
          </span>
          {/* <span className={classes.dot}>&#183;</span>
          <Link to={'/litepaper'} className={classes.linkRouter}>
            <span className={classes.link}>Litepaper</span>
          </Link> */}
        </Typography>
      </Box>
    </div>
  );
}

export default AddNFTFooter;
