export enum apiRoutesEnum {
  AUTH_RENEW = '/auth/access-token',
  LOGIN = 'auth/login',
  RESET_PASSWORD = '/reset-password',
  FORGOT_PASSWORD = '/forgot-password',
  AUTHORS = '/authors',
  SPONSORS = '/sponsors',
  GET_COLLECTIONS = '/collections',
  GET_NFTS = '/nfts/search',
  GET_SPONSORS_RANDOM = '/sponsors/random',
  GET_NFT_PREVIEW = '/nfts/parse',
  USER = '/users',
  GET_SUMMARIZE = '/nfts/summarize',
  GET_DETAIL_NFT = '/nfts',
  GET_MY_NFTS = '/nfts/me',
  GET_ALL_BLOCKS = '/nfts/blocks',
  UPDATE_USER = '/users/update-profile',
  GET_MY_NOTIFICATIONS = '/notifications',
  GET_NFT_ESTIMATE = '/nfts/estimate',
  // GET_NFT_ESTIMATE = '/nfts/estimate-v2',
  GET_NFT_PRICE = '/coins',
  GET_TRANSACTIONS = '/transactions',
  GET_USER_BLOCKERS = '/users/admin',
  BLOCK_USER = '/users/block-user',
  UNBLOCK_USER = '/users/unblock-user',
  GET_TRANSACTION_VOLUME = '/transactions/volume',
  MAILS = 'mails',
  NFTS_ADMIN = 'nfts/admin',
  NFTS_DELETE = 'nfts',
  RECENT_SALES = '/sales',
  GET_BLOCKS_BY_TIME = '/nfts/block-times',
  GET_MINIUM_PRICE_PER_DAY = '/configs',
  UPDATE_MINIUM_PRICE_PER_DAY = '/configs',
  SEARCH_CATEGORIES_COLLECTIONS = '/categories/search',
  GET_CATEGORIES = '/categories',
  GET_CATEGORIES_BLOCK = '/categories/blocks',
  SEARCH_CATEGORIES_BLOCK = '/nfts/search',
  TRANSACTIONS = '/transactions',
  GET_NFT_PREVIEW_MANUAL = '/nfts/parseV2',
  GET_COLLECTIONS_NAME = '/collections/names',
  ADD_NFT_MANUAL = '/nfts/create',
}

export enum clientRoutesEnum {
  HOME = '/',
  ADD_NFT = '/add-nft',
  USER_PAGE = '/user',
  MY_DETAIL_NFT = '/my-detail-nft/:nftId',
  DETAIL_NFTS = '/detail-nfts',
  ADMIN = '/admin',
  EDIT_NFT = '/edit-nft/:nftId',
  FAQ = '/faq/:questionParam',
  HELP_CENTER = '/help-center',
  CONTACT = '/contact',
  ADMIN_LOGIN = '/admin-login',
  ABOUT = '/about',
  TERMS_SERVICES = '/terms-of-service',
  PRIVACY = '/privacy',
  CATEGORIES = '/categories',
  NFT = '/nft',
  LITEPAPER = '/litepaper',
}

export const ALL_CATEGORIES = [
  '/nft',
  '/eth',
  '/verified',
  '/opensea',
  '/bluechip',
  '/sound',
  '/degen',
  '/lowcost',
  '/looksrare',
  '/bayc',
  '/sol',
  '/magiceden',
  '/ape',
  '/azuki',
  '/gif',
  '/pudgypenguins',
  '/veefriends',
  '/coolcats',
  '/digidaigaku',
  '/mfers',
  '/deadfellaz',
  '/cryptodickbutts',
  '/nouns',
  '/free',
  '/cryptopunks',
  '/ens',
  '/land',
  '/punk',
  '/doodles',
  '/beeple',
  '/decentraland',
  '/clonex',
  '/sandbox',
  '/cyberkongz',
  '/moonbirds',
  '/meebits',
  '/worldofwomen',
  '/goblintown',
  '/invisiblefriends',
  '/cryptoadz',
  '/guttergang',
  '/degods',
  '/pepe',
];
