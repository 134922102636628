/* eslint-disable */
import {
  Box,
  Divider,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import Question from './Question';
import { questionContent, IQuestionContent } from './questionContent';
import { useParams } from 'react-router-dom';
import Search from './Search';
import { useSelector } from 'react-redux';
import { modeTheme } from 'store/selectors';
import { TRANSPARENCY } from 'material/colors';

interface IQuestionDetail {
  questionSelected: IQuestionContent;
}

const QuestionDetail = ({ questionSelected }: IQuestionDetail) => {
  const classes = useStyles();
  const mode = useSelector(modeTheme);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [popup, setPopup] = useState<boolean>(false);

  const params = useParams<{ questionParam: string }>();
  const isquestionSelectedPage = params.questionParam !== 'all';

  const handleClick = () => {
    setPopup(!popup);
  };

  const answers = useMemo(() => {
    return (
      <div
        className={classes.answer}
        style={{
          whiteSpace: 'pre-line',
        }}
      >
        {questionSelected.answer}
      </div>
    );
  }, [questionSelected]);

  return (
    <Box className={classes.main}>
      <Box className={classes.listQuestion}>
        {isDesktop && isquestionSelectedPage && (
          <Search
            className={classes.search}
            classContainer={classes.searchContainer}
          />
        )}
        {isDesktop && !isquestionSelectedPage && <Divider />}
        {isDesktop ? (
          <div
            style={{
              height: 500,
              overflow: 'auto',
              marginTop: 100,
            }}
          >
            {questionContent.map((question) => (
              <Question questionValue={question} isDetail={true} />
            ))}
          </div>
        ) : (
          <Question
            questionValue={questionSelected}
            isDetail={true}
            downArrow
            onClick={handleClick}
            className={classes.questionDetailMb}
          />
        )}
        {!isDesktop && popup && (
          <div className={classes.questionPopup}>
            {questionContent
              .filter((question) => question.id !== questionSelected.id)
              .map((question) => (
                <Question
                  questionValue={question}
                  isDetail={true}
                  onClick={handleClick}
                />
              ))}
          </div>
        )}
      </Box>
      <Box className={classes.questionDetail}>
        <Typography className={classes.title}>
          {questionSelected.question}
        </Typography>
        {answers}
        {questionSelected.img && (
          <img className={classes.image} src={questionSelected.img} alt="" />
        )}
      </Box>
    </Box>
  );
};

export default QuestionDetail;

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      color: isDarkMode ? theme.colors.white : theme.colors.black,
    },
    main: {
      paddingTop: 100,
      marginLeft: 100,
      marginRight: 340,
      marginBottom: 100,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
        padding: 0,
        margin: '42px 0 0 0',
      },
    },
    listQuestion: {
      position: 'relative',
      width: '345',
      marginRight: 100,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    questionDetail: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        marginTop: 80,
        alignSelf: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'unset',
      },
    },
    questionDetailMb: {
      background: 'unset',
    },
    boxQuestion: {
      display: 'flex',
      flexDirection: 'row',
    },
    question: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '22.4px',
      padding: '20px 24px 20px 24px',
      width: '100%',
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '28px',
      paddingBottom: 32,
    },
    answer: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22.4px',
      paddingBottom: 40,
      color: isDarkMode ? theme.colors.white : theme.colors.black,
    },
    image: {
      width: '100%',
      objectFit: 'cover',
    },
    questionPopup: {
      position: 'absolute',
      overflow: 'auto',
      height: 312,
      width: '100%',
      bottom: -315,
      zIndex: 9,
      backgroundColor: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.white,
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',

      '&::-webkit-scrollbar': {
        background: 'transparent',
        width: '8px !important',
        [theme.breakpoints.down('sm')]: {
          width: '7px !important',
        },
      },
    },

    searchContainer: {
      height: 'unset !important',
      position: 'absolute',
      width: '100%',
      margin: 0,
    },
    search: {
      width: '100%',
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  };
});
